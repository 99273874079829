@use 'sass:map';
@import './global-variables';

// https://developers.google.com/fonts/docs/css2
// Being specific about the font weights we want saves bandwidth (reducing load time)
// The query string format is confusing. Try reading it as key-value pairs separated by ';'
// Fetch font weights 100,200,400,500,600,700 for regular text (key=0)
$font-weights-regular: '0,100..200;0,400..700';
// Fetch font weights 200,400,700 for italic text (key=1)
$font-weights-italic: '1,200;1,400;1,700';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@#{$font-weights-regular};#{$font-weights-italic}&display=swap');

@import './styles/bootstrap-theme';

@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/type';
@import '../node_modules/bootstrap/scss/images';
@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/tables';
@import '../node_modules/bootstrap/scss/forms';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/dropdown';
@import '../node_modules/bootstrap/scss/button-group';
@import '../node_modules/bootstrap/scss/nav';
@import '../node_modules/bootstrap/scss/navbar';
@import '../node_modules/bootstrap/scss/card';
@import '../node_modules/bootstrap/scss/accordion';
@import '../node_modules/bootstrap/scss/breadcrumb';
@import '../node_modules/bootstrap/scss/badge';
@import '../node_modules/bootstrap/scss/alert';
@import '../node_modules/bootstrap/scss/progress';
@import '../node_modules/bootstrap/scss/list-group';
@import '../node_modules/bootstrap/scss/close';
@import '../node_modules/bootstrap/scss/toasts';
@import '../node_modules/bootstrap/scss/modal';
@import '../node_modules/bootstrap/scss/tooltip';
@import '../node_modules/bootstrap/scss/popover';
@import '../node_modules/bootstrap/scss/spinners';
@import '../node_modules/bootstrap/scss/offcanvas';
@import '../node_modules/bootstrap/scss/helpers';
@import '../node_modules/bootstrap/scss/utilities/api';

@import './biologics-material-theme/material-overrides';
@import './biologics-material-theme/biologics-material-theme';

@import './styles/bootstrap-custom-styles';
@import './styles/angular-split';
@import './styles/page-container';
@import './styles/helpers';
@import './styles/material-icons';
@import './styles/recaptcha';
@import './styles/grid-themes/grid-theme';
@import './styles/grid-themes/report-theme';
@import './styles/report/report';
