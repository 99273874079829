@use 'sass:map';
@use 'sass:color';
@import '../../node_modules/bootstrap/scss/functions';

// Variable overrides go here
// Values that have !default are not overridden but are referenced in this file

// Options: https://getbootstrap.com/docs/5.3/customize/options/
// Enables (somewhat hacky) classes like mb-n1 for negative margins
// We use these with icon buttons and other finicky things
$enable-negative-margins: true; // false !default;
// We need to override this to get light text on primary buttons, but it doesn't
// meet accessibility guidelines: https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
// TODO: Reset to 4.5 when we switch to the new palette
$min-contrast-ratio: 2.5; // 4.5 !default;
// Color system
// From the old bootstrap theme
$geneious-orange: #f47b20;
$geneious-light-gray: hsl(240, 1%, 35%);
// These are unused
$geneious-dark-gray: hsl(0, 0%, 12%);
$geneious-light-green: #d5ebdc;
$geneious-blue: #03a9f4;

// Grays (all default apart from the geneious ones)
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: $geneious-blue;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: $geneious-orange;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800,
);

$primary: $blue;
$secondary: $geneious-light-gray;
$success: $green;
$info: #39a2d2; // Used for background in free tier login
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

// These colors were changed in v5 for compatibility with dark mode.
// Here we reset them to match v4 colors
$body-secondary-color: $gray-600; // used for .text-muted (which is now deprecated)
$body-tertiary-bg: $gray-200;

// Spacing
$spacer: 1rem !default;
// Tighten default row gutters to match .g-3 rather than .g-4
// This is closer to the BS4 class .form-row, which provided tighter margins than .row.
// We used .form-row far more frequently than .row. For wider row spacing, add .g-4 or .g-5.
$grid-gutter-width: 1rem; // 1.5rem !default;

// Fonts
$font-family-sans-serif: Roboto, sans-serif;
// Affects "rem" and therefore all text and padding
$font-size-root: 14px; // null !default;
$font-size-base: 1rem !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.15; // 1.25 !default;
$lead-font-size: $font-size-lg;
$blockquote-font-size: $font-size-lg;
$body-color: $gray-900 !default;

// Headings
$h5-font-size: $font-size-lg;
$headings-font-weight: 500 !default;
// Display is for large headings
$display-font-weight: 200; // 300 !default;
$headings-margin-bottom: $spacer; // $spacer / 2 !default;

// Links
$link-color: #004080; // $primary !default;
$link-hover-color: $primary; // darken($link-color, 15%) !default;
$link-decoration: none; // Make link underline only visible on hover, like in Bootstrap 4
$link-hover-decoration: underline;

// Buttons & inputs

// Remove custom styles for checkboxes and radios until we sort out the theme colors
$form-check-input-bg: inherit;
$form-check-input-border: inherit;
$form-check-input-border-radius: inherit;
$form-check-input-focus-border: inherit;
$form-check-input-focus-box-shadow: inherit;
$form-check-input-checked-color: inherit;
$form-check-input-checked-bg-color: inherit;
$form-check-input-checked-border-color: inherit;
$form-check-input-checked-bg-image: inherit;
$form-check-radio-checked-bg-image: inherit;
$form-check-input-indeterminate-color: inherit;
$form-check-input-indeterminate-bg-color: inherit;
$form-check-input-indeterminate-border-color: inherit;
$form-check-input-indeterminate-bg-image: inherit;

$input-btn-padding-y: 0.3rem; // 0.375rem !default;
$input-btn-padding-x: 0.75rem; // 0.75rem !default;
$input-btn-padding-y-sm: 0.2rem; // 0.25rem !default;
$input-btn-padding-x-sm: 0.4rem; // 0.5rem !default;
$input-btn-padding-y-lg: 0.35rem; // 0.5rem !default;
$input-btn-padding-x-lg: 0.875rem; // 1rem !default;

// Navs
$nav-tabs-link-active-color: $gray-900; // $gray-700 !default;
$nav-link-padding-y: 0.3rem; // .5rem !default;
$nav-link-padding-x: 0.75rem; //  1rem !default;
$nav-link-color: $body-color;
$nav-link-hover-color: $body-color;

// Navbar
$navbar-padding-y: $spacer * 0.25;
$navbar-padding-x: $spacer * 0.5;

// Dropdowns
$dropdown-font-size: $font-size-sm;
$dropdown-header-color: $gray-600 !default;
$dropdown-item-padding-y: 0.3 * $spacer; // 0.25 * $spacer !default;

// Cards
$card-cap-bg: rgba(0, 0, 0, 0.03); // taken from Bootstrap 4 defaults
$card-spacer-y: $spacer; // $spacer !default;
$card-spacer-x: $spacer; // $spacer !default;

// Accordions
$accordion-body-padding-x: 0.5rem;
$accordion-body-padding-y: 0.625rem;

// Popovers
$popover-max-width: 500px; // 276px !default;

// Badges
$badge-font-size: $font-size-sm; // $font-size-base * 0.75 !default;

// Tooltips
$tooltip-font-size: $font-size-base; // $font-size-base * 0.75 !default;

// Progress bars
$progress-height: 1.25rem; // 1rem !default;
$progress-font-size: $font-size-sm; // $font-size-base * 0.75 !default;

// Breadcrumbs
$breadcrumb-bg: transparent; // $gray-200 !default;
$breadcrumb-active-color: $gray-800; // $gray-600 !default;
$breadcrumb-padding-y: 0.5 * $spacer; // 0 !default;
$breadcrumb-padding-x: 0.75 * $spacer; // 0 !default;
$breadcrumb-margin-bottom: 0; // 1rem !default;

// Spinners
$spinner-width: 1.3rem; // 2rem !default;
$spinner-border-width: 0.19em; // 0.25em !default;
$spinner-width-sm: 0.875rem; // 1rem !default;
$spinner-border-width-sm: 0.15em; // 0.2em !default;

// Popovers
$popover-body-padding-y: 0.5 * $spacer;
$popover-body-padding-x: 0.5 * $spacer;

@import '../../node_modules/bootstrap/scss/variables';

// Map overrides
// https://getbootstrap.com/docs/5.3/customize/color/#color-sass-maps
// Generate utilities for Geneious Orange color, e.g. bg-brand, btn-brand, text-brand
$custom-colors: (
  'brand': $geneious-orange,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/utilities';

// Utility customization
// https://getbootstrap.com/docs/5.3/utilities/api/

// These utilities are replacements for hard-coded values in stylesheets
// This goes against Bootstrap's responsive design ideology, but at least
// it's centralized ¯\_(ツ)_/¯
$static-sizes: (
  1: 16rem,
  2: 21.5rem,
  3: 25rem,
  4: 36rem,
  5: 46.5rem,
);

$utilities: map-merge(
  $utilities,
  (
    // Utilities for setting `min-width: 0` and `min-height: 0`
    'min-width':
      (
        property: min-width,
        class: min-w,
        values: (
          0: 0,
        ),
      ),
    'min-height': (
      property: min-height,
      class: min-h,
      values: (
        0: 0,
      ),
    ),
    // Static sizing utilities
    'width-static':
      (
        property: width,
        class: w-static,
        values: $static-sizes,
      ),
    'min-width-static': (
      property: min-width,
      class: min-w-static,
      values: $static-sizes,
    ),
    'max-width-static': (
      property: max-width,
      class: mw-static,
      values: $static-sizes,
    ),
    'height-static': (
      property: height,
      class: h-static,
      values: $static-sizes,
    ),
    'min-height-static': (
      property: min-height,
      class: min-h-static,
      values: $static-sizes,
    ),
    'max-height-static': (
      property: max-height,
      class: mh-static,
      values: $static-sizes,
    )
  )
);
